<!--do not modify this file until not required-->
<template>
    <div class="row">
        <div class="col-12 text-center py-2" v-if="getLoader">
            <i class="fa fa-spin fa-spinner"></i>
        </div>
        <div class="col-12 col-lg-3 col-md-3">
            <div class="mb-4 search-width">
                <treeselect
                    @change="handleSearch"
                    :disable="getLoader" class="default-select"
                    id="perPage"
                    v-model="query.perPage"
                    :multiple="false" :options="_.map(pageSelection,(i) => {
                    return {
                        id:i,
                        label:i
                    }
                })" :disableFuzzyMatching="true" />
            </div>
        </div>
        <div class="col-12 col-lg-9 col-md-9">
            <div class="input-group mb-4 input-search">
                <b-form-input :disable="getLoader" v-model="query.search" placeholder="Enter to search"
                              class="w-25 me-2"
                              @keyup.enter="handleSearch" />
                <button :disabled="getLoader"
                        @click="handleSearch"
                        title="search"
                        class="btn btn-primary me-2">
                    <i class="bi bi-search"></i>
                </button>
                <!-- slot for add new button-->
                <slot></slot>
                <!-- slot for filter button-->
                <slot name="filter-slot"></slot>
                <button :disabled="getLoader"
                        class="btn btn-primary refresh" title="refresh" @click="refreshTableData">
                    <i class="bi bi-bootstrap-reboot"></i>
                </button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="table-responsive">
                <b-table
                    responsive
                    :items="dataSources"
                    :fields="columns"
                    v-if="columns.length"
                    label-sort-asc="↑"
                    label-sort-desc="↓"
                    label-sort-clear=""
                    show-empty
                    empty-text="No Result Found..."
                    :sort-by.sync="query.orderBy"
                    :sort-desc.sync="sortBy"
                >
                    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
                    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                        <slot :name="slot" v-bind="scope"></slot>
                    </template>
                </b-table>
                <div class="mt-2" v-if="dataSources.length > 0 && pagination.total > pagination.per_page">
                    <nav aria-label="Page navigation" class="data-pagination custom-pagination">
                        <b-pagination v-if="pagination"
                                      v-model="query.page"
                                      :total-rows="pagination.total"
                                      :per-page="query.perPage"
                        ></b-pagination>
                    </nav>
                </div>
            </div>
        </div>
        <b-modal v-model="deleteModal" id="deleteModal" title="Delete Confirmation" ok-title='Yes'
                 @ok="handleDelete">
            <p class="my-4">Are you want to delete item?</p>
        </b-modal>
    </div>
</template>

<script>
import ListingMixin from "@/Util/ListingMixin";
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { request } from "@/Util/Request";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    props: ['modelId'],
    mixins: [ListingMixin],
    components: { Treeselect },
    data() {
        return {
            modal: null,
            deleteUrl: null,
            deleteModal: false,
            tableName: null
        };
    },
    methods: {
        handleTableRefreshList() {
            this.handleRefreshList();
        },
        async handleDelete() {
            if ( this.deleteUrl ) {
                try {
                    const response = await request({
                        method: 'delete',
                        url: this.deleteUrl,

                    });
                    this.$snotify.success(`${ this.tableName } deleted successfully!`);
                    this.handleRefreshList();
                } catch (error) {
                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    }
                }
            }
        },
        handleDeleteShow(url, name) {
            this.deleteUrl = url;
            this.deleteModal = true;
            this.tableName = name;
        },
    }
};
</script>

<style>
.b-sidebar-footer {
    padding: 0;
}

.custom-pagination .page-item:nth-child(1),
.custom-pagination .page-item:nth-last-child(1) {
    display: none;
}

table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), .table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: left calc(0.75rem / 2) center !important;
    padding-right: 0 !important;
}

table .position-relative div, table .position-relative span {
    margin-left: 1rem !important;
}
</style>
