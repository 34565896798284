<template>
    <header class="base-header">
        <nav
            class="d-flex flex-wrap align-items-center justify-content-beetween justify-content-lg-start navbar-expand-lg p-0 position-static"
        >
            <router-link
                :to="{ name: 'home' }"
                class="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none navbar-brand"
            >
                <img src="../assets/img/medicrowd_logo.svg" alt="Logo" class="header-logo" />
            </router-link>

            <router-link v-if="!user" :to="{ name: 'Login' }"
                         class="nav-link text-secondary d-flex d-lg-none  fs-18"
            >{{translateLanguage('menu.logIn')}}
            </router-link>
            <div class="d-flex d-lg-none">
                <AccountDropdown v-if="user" :isChildButtonOpen="isChildButtonOpen"
                                 @childButtonToggle="handleChildButtonToggle" />

              <div class="language-dropdown">
                <div class="selected-item" @click="toggleDropdown">
                  <img :src="selectedItem?.image" :alt="selectedItem?.name" width="20" height="20">
                  <label>{{ selectedItem?.name }}</label>
                  <i class="fas fa-chevron-down"></i>
                </div>
                <div class="language-dropdown-list" v-if="isDropdownOpen">
                  <div v-for="item in languages" :key="item.value" @click="selectItem(item)">
                    <img :src="item?.image" :alt="item.name" width="20" height="20">
                    <label> {{ item.name }}</label>
                  </div>
                </div>
              </div>

            </div>
            <div class="text-end d-flex align-items-center d-block d-lg-none mx-auto">
                <!-- <a href="#" class="nav-link px-3 text-secondary d-block me-3 fs-18">Log in</a> -->
                <button
                    :disabled="isCheckNewProjectCurrentRoute"
                    type="button"
                    class="btn main-btn d-block fs-18"
                    @click="redirectRoute"
                >
                    {{translateLanguage("menu.startAProject")}}
                </button>
            </div>
            <button
                class="navbar-toggler"
                type="button"
                ref="navbartoggler"
                data-bs-toggle="collapse"
                :data-bs-target="this.$route.name == 'project' || this.$route.name ==  'projectManagement' ? '#navbarSupportedContents' : '#navbarSupportedContent'"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="toggleButtons"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent" v-on:click="collapseNavBar"
                 ref="navbarElement">
                <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ms-4">
                    <li>
                        <router-link :to="{ name: 'howItWorks' }" class="nav-link px-3 text-secondary fs-18"
                        >{{translateLanguage('menu.howItsWork.heading')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'news' }" class="nav-link px-3 text-secondary fs-18"
                        >{{translateLanguage('menu.news')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'ourStory' }" class="nav-link px-3 text-secondary fs-18"
                        >{{translateLanguage('menu.ourStory')}}
                        </router-link>
                    </li>
                    <li class="position-relative">
                        <a
                            href="#"
                            class="nav-link px-3 text-secondary dropdown-toggle fs-18"
                            id="navbarDropdownMenuLink"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >{{translateLanguage('menu.browseProjects.title')}}</a
                        >
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <router-link :to="{ name: 'browseProjects' }" class="dropdown-item fs-18">{{ translateLanguage('menu.browseProjects.show_all')}}
                                </router-link>
                            </li>
                            <li v-for="(category, index) in categories" :key="index">
                                <router-link :to="{ name: 'browseProjects', params: { id: category.id } }"
                                             class="dropdown-item fs-18">{{ translateLanguage('menu.browseProjects.'+category.name.replace(/\s+/g, '_').replace(/\//g, '_')) }}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>

                <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-2 search">
                    <div class="search__wrapper d-flex justify-content-end position-relative animated fs-18">
                        <input type="text" name="search" class="search" :placeholder="translateLanguage('search')" />
                    </div>
                </form>
                <div class="text-end d-flex align-items-center d-none d-md-flex">
                    <router-link v-if="!user" :to="{ name: 'Login' }"
                                 class="nav-link px-3 text-secondary d-block me-3 fs-18"
                    >{{translateLanguage('menu.logIn')}}
                    </router-link>

                  <div class="language-dropdown " :class="!user?'mr-35':''">
                    <div class="selected-item" @click="toggleDropdown">
                      <img :src="selectedItem?.image" :alt="selectedItem?.name" width="20" height="20">
                      <label>{{ selectedItem?.name }}</label>
                      <i class="fas fa-chevron-down"></i>
                    </div>
                    <div class="language-dropdown-list" v-if="isDropdownOpen">
                      <div v-for="item in languages" :key="item.value" @click="selectItem(item)">
                        <img :src="item?.image" :alt="item.name" width="20" height="20">
                       <label> {{ item.name }}</label>
                      </div>
                    </div>
                  </div>

                    <AccountDropdown v-if="user" :isChildButtonOpen="isChildButtonOpen"
                                     @childButtonToggle="handleChildButtonToggle" />
                    <button
                        :disabled="isCheckNewProjectCurrentRoute"
                        type="button"
                        class="btn main-btn d-block fs-18"
                        @click="redirectRoute"
                    >
                      {{translateLanguage("menu.startAProject")}}
                    </button>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import AccountDropdown from './AccountDropdown.vue';
import { mapGetters } from 'vuex';
import { request } from "@/Util/Request";
import { hasAuthUser } from "@/Util/auth";
import { EventBus } from '@/event-bus';

export default {
    name: 'Header',
    components: {
        AccountDropdown,
    },
    data() {
        return {
            categories: [],
            isChildButtonOpen: false,
            isNavButton: false,
          // defaultLanguage: 'en',
             selectedItem: null,
             isDropdownOpen: false,
        };
    },
    computed: {
        ...mapGetters(['user','defaultLanguage']),
        isCheckNewProjectCurrentRoute() {
            return _.includes(['project', 'projectId'], this.$route.name);
        },
    },
    mounted() {
        this.categoriesDetail();
        document.addEventListener('click', this.closeNavBarIfOpen);
        this.selectedItem = this.languages.find(lang => lang.name === this.defaultLanguage);
      if (this.defaultLanguage === 'he') {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      } else {
        document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      }
    },
    methods: {
        async redirectRoute() {
            this.$store.dispatch('setProjectOldItem', null);
            if ( this.user ) {
                await this.$router.replace({ name: 'project' });
            } else {
                if ( this.$route.name !== 'Login' ) {
                    await this.$router.replace({ name: 'Login' });
                }
            }
        },
        async categoriesDetail() {
            try {
                const response = await request({
                    method: 'GET',
                    url: '/categories'
                });

                const { data } = response;
                this.categories = data;
            } catch (error) {
                this.$snotify.error("Something went wrong");
            }
        },
        collapseNavBar(event) {
            if (
                document.querySelector('button[data-bs-target="#navbarSupportedContent"]') &&
                document.getElementById('navbarSupportedContent').classList.contains('show')
            ) {
                if ( event.target.classList.contains('dropdown-toggle') ) {
                    return;
                }
                if ( !event.target.classList.contains('search') ) {
                    document.querySelector('button[data-bs-target="#navbarSupportedContent"]').click();
                }
            }
        },
        closeNavBarIfOpen(event) {
            if ( typeof this.$refs.navbarElement !== 'undefined' ) {
                if ( !this.$refs.navbarElement.contains(event.target) ) {
                    if ( document.getElementById('navbarSupportedContent').classList.contains('show') ) {
                        document.getElementById('navbarSupportedContent').classList.remove('show');
                    }

                }
            }
        },
        toggleButtons() {
            this.isChildButtonOpen = !this.isChildButtonOpen;


        },
        handleChildButtonToggle(isOpen) {
            this.isChildButtonOpen = isOpen;
            if ( document.getElementById('navbarSupportedContent') && document.getElementById('navbarSupportedContent').classList.contains('show') ) {
                document.getElementById('navbarSupportedContent').classList.remove('show');
            }
            if ( document.getElementById('navbarSupportedContents') && document.getElementById('navbarSupportedContents').classList.contains('show') ) {
                document.getElementById('navbarSupportedContents').classList.remove('show');
            }
        },
        toggleDropdown() {
          this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectItem(item) {
          this.selectedItem = item;
          // this.defaultLanguage = item.name;
          // EventBus.$emit('languageChanged', this.defaultLanguage);
          var htmlElement = document.getElementsByTagName("html")[0];

          if(item.name == 'he'){
            htmlElement.setAttribute("dir", "rtl");
          }else{
            htmlElement.setAttribute("dir", "ltr");
          }
          this.$store.dispatch('updateDefaultLanguage', item.name);
          this.isDropdownOpen = false;
        }
    },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/components/header.scss';
</style>
