<template>
    <div id="app">
        <vue-snotify></vue-snotify>
        <router-view />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hasAuthUser, getAuthUser } from "@/Util/auth";

export default {
    name: 'App',
    mounted() {
        if ( _.isEmpty(this.user) && hasAuthUser() ) {
            this.$store.dispatch("user", getAuthUser());
        }
    },
    computed: {
        ...mapGetters(['user', 'getUnAuthorized']),
        unAuthorizedValue() {
            return this.getUnAuthorized;
        }
    },
    watch: {
        'unAuthorizedValue': function (newValue) {
            if ( newValue == true ) {
                this.$snotify.error('Session expired, try again');
            }
        }
    }
};
</script>
