<template>
    <nav
        id="sidebar"
        :class="[
              'col-md-3 col-lg-2 bg-light sidebar  p-0',
              { hide: !isSidebarOpen },
              { show: isSidebarOpen },
        ]"
    >
        <router-link  :to="{ name: 'Dashboard' }"
                     class="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none navbar-brand">
            <img src="@/assets/img/medicrowd_logo.svg" alt="Logo" class="header-logo" />
        </router-link>
        <div class="position-sticky py-4">
            <ul class="nav flex-column">
                <li class="nav-item" @click="toggleSidebar">
                    <router-link :class="[`nav-link`,{'active': $route.name == 'Dashboard'}]"
                                 :to="{ name: 'Dashboard' }">
                        <span class="sidebar-icon me-2"> <i class="bi bi-circle"></i></span>
                        <span class="ml-2">Dashboard</span>
                    </router-link>
                </li>
                <li class="nav-item"  @click="toggleSidebar">
                    <router-link
                        :class="[`nav-link`,{'active': _.includes(['AdminUser','AdminUserForm'],$route.name )}]"
                        :to="{ name: 'AdminUser' }">
                        <span class="sidebar-icon me-2"> <i class="bi bi-circle"></i></span>
                        <span class="ml-2">User</span>
                    </router-link>
                </li>
                <li class="nav-item"  @click="toggleSidebar">
                    <router-link
                        :class="[`nav-link`,{'active': _.includes(['AdminProject','AdminProjectForm', 'AdminProjectApprovedForm'],$route.name )}]"
                        :to="{ name: 'AdminProject' }">
                        <span class="sidebar-icon me-2"> <i class="bi bi-circle"></i></span>
                        <span class="ml-2">Project</span>
                    </router-link>
                </li>
                <li class="nav-item"  @click="toggleSidebar">
                    <router-link
                        :class="[`nav-link`,{'active': _.includes(['AdminTeam','AdminTeamForm'],$route.name )}]"
                        :to="{ name: 'AdminTeam' }">
                        <span class="sidebar-icon me-2"> <i class="bi bi-circle"></i></span>
                        <span class="ml-2">Team</span>
                    </router-link>
                </li>
                <li class="nav-item"  @click="toggleSidebar">
                    <router-link
                        :class="[`nav-link`,{'active': _.includes(['AdminNews','AdminNewsForm'],$route.name )}]"
                        :to="{ name: 'AdminNews' }">
                        <span class="sidebar-icon me-2"> <i class="bi bi-circle"></i></span>
                        <span class="ml-2">News</span>
                    </router-link>
                </li>
                <li class="nav-item"  @click="toggleSidebar">
                    <router-link
                        :class="[`nav-link`,{'active': _.includes(['AdminFollowers'],$route.name )}]"
                        :to="{ name: 'AdminFollowers' }">
                        <span class="sidebar-icon me-2"> <i class="bi bi-circle"></i></span>
                        <span class="ml-2">Followers</span>
                    </router-link>
                </li>
                <!--                <li class="nav-item">-->
                <!--                    <router-link class="nav-link" :to="{ name: 'AdminUser' }">-->
                <!--                        <span class="sidebar-icon me-2"> <i class="bi bi-person-circle"></i></span>-->
                <!--                        <span class="ml-2">User</span>-->
                <!--                    </router-link>-->
                <!--                    <ul>-->
                <!--                        <li class="nav-item"><a href="#" class="nav-link">Stuff</a></li>-->
                <!--                        <li class="nav-item"><a href="#" class="nav-link">Stuff</a></li>-->
                <!--                    </ul>-->
                <!--                </li>-->
                <!--                <li class="nav-item">-->
                <!--                    <router-link class="nav-link" :to="{ name: 'AdminProject' }">-->
                <!--                        <span class="sidebar-icon me-2"> <i class="bi bi-tag"></i></span>-->
                <!--                        <span class="ml-2">Project</span>-->
                <!--                    </router-link>-->
                <!--                    <ul>-->
                <!--                        <li class="nav-item"><a href="#" class="nav-link">Stuff</a></li>-->
                <!--                        <li class="nav-item"><a href="#" class="nav-link">Stuff</a></li>-->
                <!--                        <li class="nav-item">-->
                <!--                            <a href="#" class="nav-link">Other</a>-->
                <!--                            &lt;!&ndash; Second Tier Drop Down &ndash;&gt;-->
                <!--                            <ul>-->
                <!--                                <li class="nav-item"><a href="#" class="nav-link">Stuff</a></li>-->
                <!--                                <li class="nav-item"><a href="#" class="nav-link">Stuff</a></li>-->
                <!--                                <li class="nav-item">-->
                <!--                                    <router-link class="nav-link" :to="{ name: 'AdminProject' }">-->
                <!--                                        <span class="sidebar-icon me-2"> <i class="bi bi-tag"></i></span>-->
                <!--                                        <span class="ml-2">Project</span>-->
                <!--                                    </router-link>-->
                <!--                                    <a href="#" class="nav-link">Other</a>-->
                <!--                                    &lt;!&ndash; Third Tier Drop Down &ndash;&gt;-->
                <!--                                    <ul>-->
                <!--                                        <li class="nav-item"><a href="#" class="nav-link">Stuff</a></li>-->
                <!--                                        <li class="nav-item"><a href="#" class="nav-link">Things</a></li>-->
                <!--                                        <li class="nav-item"><a href="#" class="nav-link">Other Stuff</a></li>-->
                <!--                                    </ul>-->

                <!--                                </li>-->
                <!--                            </ul>-->
                <!--                        </li>-->
                <!--                    </ul>-->
                <!--                </li>-->
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Sidebar",
    data() {
        return {
            // isSidebarOpen: true
        };
    },
    methods: {
        toggleSidebar() {
            if (window.innerWidth <= 768){
                this.$emit('updateToggle', !this.isSidebarOpen);
            }
        }
    },
    props: {
        isSidebarOpen: Boolean,
    },
};
</script>
