export default {
    data() {
        return {
            he: {
                menu: {
                    howItsWork: {
                        heading: 'איך זה עובד',
                        title: 'יתרונות',
                        large_enough: 'להאיר את האור על מחלות יתומות שאין להן שוק גדול מספיק כדי לקבל תמיכה ומשאבים.',
                        medical: 'סיוע במימון והגברת מחקר רפואי למציאת תרופות.',
                        contribute: 'לאפשר לאנשים עם מצבים רפואיים ולבני משפחותיהם לתרום לגילוי פתרונות.',
                        difference: 'לעשות שינוי אמיתי.'
                    },
                    logIn: 'התחברות',
                    news: 'חֲדָשׁוֹת',
                    ourStory: 'הסיפור שלנו',
                    browseProjects: {
                        title: 'עיין בפרויקטים',
                        show_all: 'הצג הכל',
                        Drug_discovery_research: 'מחקר גילוי תרופות',
                        Invention_of_medical_nursing_facility_aid_devices_utilities: 'המצאת כלי עזר למתקן סיעוד רפואי',
                        Innovative_alternative_treatment: 'טיפול אלטרנטיבי חדשני',
                        Development_of_nutritional_supplements: 'פיתוח תוספי תזונה',
                        projects: "פרויקטים",
                    },
                    startAProject: 'התחל פרויקט'
                },
                accountMenu: {
                    yourAccount: "החשבון שלך",
                    profile: "פּרוֹפִיל",
                    settings: "הגדרות",
                    favouriteProjects: "מועדפים",
                    recommendedForYou: "מומלץ עבורך",
                    new: "חָדָשׁ",
                    viewAll: "צפה בהכל",
                    logOut: "התנתקות",
                    yourProject: "הפרויקטים שלך",
                    logoutMessage: "בטוח שרצית להתנתק?"


                },
                search: 'חפש',
                name: "שֵׁם",
                home_header: 'מימון תרופות, משנה חיים',
                homePage: {
                    header: "מימון תרופות, משנה חיים",
                    content_1: "החברה שלנו מחויבת לסייע לאנשים חולים להתחבר לחוקרים במטרה למצוא תרופה למחלותיהם. אנו מאמינים כי לכל אדם מגיעה גישה למחקרים ולטיפולים הרפואיים המתקדמים ביותר, וכי באמצעות עבודה משותפת נוכל לחולל שינוי אמיתי במאבק במחלות",
                    getStartedBtn: "מתחילים",
                    popularProjects: "פרויקטים מובילים",
                    popularProjectContent: "נכון לעכשיו אין פרויקט מוביל",
                    readyToJoin: "רוצה להצטרף?",
                    receiveNews: "קבל את החדשות האחרונות וקמפיינים חדשים",
                    yourEmailAddress: 'כתובת הדוא"ל שלך',
                    spamMessage: "אל דאגה, אנו שולחים רק את החומר הטוב ביותר - ללא ספאם",
                },
                footer: {
                    connectUs: "צור קשר",
                    updates: "עדכונים",
                    pricing: "תמחור",
                    tutorials: "הדרכות",
                    teams: "צוותים",
                    resources: "משאבים",
                    examples: "דוגמאות",
                    sessions: "מפגשים",
                    program: "תכנית",
                    about: "אודות",
                    community: "קהילה",
                    company: "חֶברָה",
                    careers: "קריירה",
                    copyRight: "עוצב על ידי Checker Software",
                },
                newsPage: {
                    learnMore: "קרא עוד",
                },
                ourStory: {
                    header: "הסיפור שלנו",
                    content: "במהלך בדיקה גופנית, הרופא ילחץ על הבטן שלך או של ילדך כדי לבדוק את גודל הטחול והכבד. כדי לקבוע אם לילדך יש מחלת גושה, הרופא ישווה את הגובה והמשקל של ילדך לתרשימי גדילה סטנדרטיים. בנוסף, ייתכן שהרופא ימליץ על בדיקות מעבדה מסוימות, סריקות הדמיה וייעוץ גנטי",

                },
                profile: {
                    about: "אודות",
                    myCredentials: "ההסמכות שלי",
                    shortBio: "ביוגרפיה",
                    education: "השכלה",
                    experience: "ניסיון תעסוקתי",
                    myProjects: "הפרויקטים שלי",
                    similarProject: "פרויקטים דומים",
                    editCredentials: "ערוך הסמכות",
                    addCredentials: "הוסף הסמכות",
                    myWebsite: "האתר שלי",
                    addWebsite: "הוסף אתר",
                    editWebsite: "ערוך אתר",
                    myLinkedinPage: "עמוד הלינקדין שלי",
                    addLinkedin: "הוסף לינקדאין",
                    editLinkedin: "ערוך לינקדאין",
                    backed: "מגובה",
                    backedProjects: "פרויקטים מגובים",
                    tellExperience: "ספר לאנשים על החוויה שלך",
                    showWebsite: "הראה לאנשים את האתר שלך",
                    newProject: "פרויקט חדש",
                    showLinkedIn: "הראה לאנשים את הלינקדאין שלך",
                    credentialInfo: "המידע שלך יהיה גלוי לכל מבקר בדף הפרויקט. יש לשים לב כי צוות Medicrowd יבצע בדיקת רקע כחלק מתהליך אישור קמפיין המימון לפרויקט",
                    deleteProject: "מחק את הפרויקט",
                    deleteProjectLine1: "האם ברצונך למחוק את הפרויקט?",
                    deleteProjectLine2: "לאחר מחיקת הפרויקט, כל המידע הקשור אליו יימחק לצמיתות ולא יהיה ניתן לשחזר אותו",
                    deleteProjectLine3: "ברצונך להמשיך עם מחיקת הפרויקט?",
                },
                btn: {
                    save: "שמור",
                    cancel: "ביטול",
                    edit: "עריכה",
                    delete: "מחיקה",
                    leave: "יציאה",
                    next: "הַבָּא",
                    saveChanges: "שמור שינויים",
                    sendAgain: "שלח שוב",
                    updateEmailAddress: `עדכן כתובת דוא"ל`,
                    upload: "העלאה",
                    resetPassword: "איפוס סיסמה",
                    setPassword: "הגדר סיסמה",
                    goBack: "חזרה",
                    savePassword: "שמור סיסמה",
                    preview: "תצוגה מקדימה",
                    backBasis: "חזרה",
                    backStory: "לסיפור",
                    add: "הוספה",
                    sendInvite: "שלח הזמנה",
                    backTeam: "לצוות",
                    backMilestone: "לאבני דרך",
                    select: "בחר",
                    addBankAccount: "הוסף חשבון בנק",
                    editBankAccount: "ערוך חשבון בנק",
                    backFunding: "למימון",
                    submitReview: "שלח לבדיקת הצוות",
                    generateTag: "צור תג",
                    backPayment: "לתשלום",
                    launchProject: "השקת פרויקט",
                    launch: "השקה",
                    generateUrl: "צור כתובת אתר לפרויקט שלי",
                    confirm: "אישור",
                    backUpdate: "לעדכונים",
                    backProjectStatus: "לסטטוס הפרויקט",
                    backBackerReport: "לדוח התומכים",
                    saveDraft: "לשמור כטיוטה",
                    postUpdate: "פרסם עדכון",
                    deleteUpdate: "מחק עדכון",
                    backStatistics: "לסטטיסטיקה",
                    deleteDraft: "מחק טיוטה",
                    fundThisProject: "למימון הפרויקט",
                    addToFavorites: "הוסף למועדפים",
                    suspendUpdate: "השעיית עדכון",
                    readMore: "לקרוא עוד",
                    readLess: "לקרוא פחות",
                    reasonForSuspend: "סיבה להשעיה",
                    send: "שליחה",
                    saveProject: "שמור פרויקט",
                    submitLater: "שלח מאוחר יותר",
                    submit: "שלח",
                    exitPreview: "צא מתצוגה מקדימה",
                    backToHomeScreen: "למסך הבית",
                    projectPage: "עמוד הפרויקט",
                    update: "עדכון",

                },
                chart: {
                    funders: "תורמים",
                    addedToFavorites: "נוסף למועדפים",
                    occasionalUsers: "משתמשים מזדמנים",
                    totalCount: "ספירה כוללת"
                },
                settings: {
                    title: "הגדרות",
                    account: "חֶשְׁבּוֹן",
                    editProfile: "ערוך פרופיל",
                    paymentMethod: "אמצעי תשלום",
                    addName: "הוסף שם",
                    email: `דוא"ל`,
                    addEmail: `הוסף דוא"ל"`,
                    newEmail: `הזן דוא"ל חדש`,
                    emailNote: `שים לב ששינוי כתובת הדוא"ל שלך ידרוש אימות נוסף`,
                    changeEmail: `שנה כתובת דוא"ל`,
                    password: "סיסמה",
                    changePassword: "נא לשנות סיסמה",
                    changeYourPassword: "נא לשנות את סיסמתך",
                    currentPassword: "סיסמה נוכחית",
                    newPassword: "סיסמה חדשה",
                    confirmNewPassword: "אישור הסיסמה החדשה",
                    updateYourPassword: "נא לעדכן את הסיסמה שלך",
                    address: "כתובת",
                    street: "רְחוֹב",
                    city: "עִיר",
                    country: "מדינה",
                    creditCardNumber: "מספר כרטיס אשראי",
                    expiration: "תוקף",
                    cvc: "CVC",
                    holdersName: "שם בעל/ת הכרטיס",
                    editCreditCard: "עריכת כרטיס אשראי",
                    addCreditCard: "הוספת כרטיס אשראי",
                    verifyEmail: `נא לאמת את הדוא"ל שלך`,
                    notGetCode: "לא קיבלת קוד?",
                    sentCodeMessage: "שלחנו קוד אימות בן 4 ספרות אל",
                    passwordMessage: "מינימום 8 תווים",
                    profileChange: "נא לשנות תמונת פרופיל",
                },
                login: {
                    signIn: "להתחבר",
                    logIn: "התחברות",
                    rememberMe: "זכור אותי",
                    forgotPassword: "שכחת סיסמה?",
                    signUp: "הרשמה",
                    forgotPasswordContent: 'נא להזין את כתובת הדוא"ל שלך, אנו נשלח לך קישור לאיפוס הסיסמה שלך',
                    resetPasswordContent: 'קישור לאיפוס סיסמה נשלח לדוא"ל שלך בהצלחה.',
                    setNewPassword: "נא להגדיר סיסמה חדשה",
                    confirmPassword: "נא לאשר סיסמה",
                    otp: "otp",
                    pass: "עבור",
                    wrongEmailContent: `כתובת דוא"ל שגויה?`,
                    choosePassword: "בחר סיסמה",
                    alreadyAccount: "כבר יש לך חשבון?",
                    newUser: "חדש ב-Medicrowd"
                },
                projectPage: {
                    basic: "מידע כללי",
                    story: "הסיפור",
                    team: "הצוות",
                    milestones: "אבני הדרך",
                    funding: "מימון",
                    payment: "תַשְׁלוּם",
                    promotion: "קידום",
                    projectStatus: "סטטוס הפרויקט",
                    backerReport: "דוח תומכים",
                    statistics: "סטטיסטיקות",
                    updates: "עדכונים",
                    primaryCategory: "קטגוריה ראשית",
                    primaryCategoryPlaceholder: "בחר בקטגוריה ראשית",
                    countryOfOrigin: "מדינה",
                    countryOfOriginPlaceholder: "בחר את המדינה שלך",
                    projectTitle: "כותרת הפרויקט",
                    projectTitleContent: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    title: "כותרת",
                    subTitle: "כותרת משנית",
                    projectImage: "תמונת פרויקט",
                    projectImageContent: "נא להוסיף תמונה שמייצגת בבירור את הפרויקט שלך. יש לבחור תמונה שנראית טוב בגדלים שונים - היא תופיע בדף הפרויקט שלך, ברחבי אתר האינטרנט של Medicrowd ובערוצים חברתיים בהם ישותף הפרויקט",
                    projectImageContent1: "תמונת הפרויקט צריכה להיות לפחות 1024x576 פיקסלים. היא תחתך ליחס של 16:9",
                    projectRadio: "נא להשתמש בתמונה כבאנר ראשי בדף הפרויקט",
                    projectVideo: "סרטון פרויקט",
                    projectVideoContent: "נא להוסיף סרטון המתאר אותך ואת הפרויקט שלך, את הסיפור שעומד מאחוריו, סיבת גיוס הכספים, מה בכוונתך לעשות בעזרת המימון וכיצד חיים ישתנו בעקבותיו",
                    fundingDuration: "תקופת המימון",
                    fundingDurationOption: "נא לבחור את האפשרות העדיפה ביותר",
                    fixedNumberDays: "מספר ימים קבוע",
                    fixedNumberText: "נא להזין מספר ימים בין 1-60",
                    endingDate: "תאריך סיום",
                    projectDescription: "תיאור הפרויקט",
                    projectDescriptionPlaceholder: "סיפור הפרויקט",
                    projectDescriptionContent: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    riskChallenges: "סיכונים ואתגרים",
                    riskChallengesContent: "היו כנים לגבי הסיכונים והאתגרים הפוטנציאליים של הפרויקט ותארו את דרכי ההתמודדות כדי להגיע להשלמתו",
                    riskChallengesContent2: "סיכונים שאתה רוצה לטפל בהם כוללים תקציב, אבני דרך וכו'",
                    yourTeam: "הצוות שלך",
                    yourTeamContent: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    showPartOfTeam: "הראה אותי כחלק מצוות",
                    you: "עליי",
                    teamMemberAdd: "הוסף חבר/ת צוות",
                    teamMemberEdit: "ערוך חבר/ת צוות",
                    full_name: "שם מלא",
                    photo: "תמונה",
                    yourCompany: "החברה שלי",
                    yourCompanyContent: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    optional: "אופציונלי",
                    showLogoText: "הצג את לוגו החברה בדף הפרויקט",
                    companyLogo: "לוגו החברה",
                    companyName: "שם החברה",
                    additionalProjectManager: "מנהל פרויקט נוסף",
                    additionalText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    projectManager: "מנהל פרויקט",
                    projectManagerText: "הוסף חברי צוות שיעזרו לך לנהל את דף הפרויקט. חשוב לציין שחברי צוות שאין להם חשבון ב-Medicrowd יצטרכו להירשם על מנת להשתתף בניהול הפרויקט",
                    permissions: "הרשאות",
                    edit_project: "ערוך פרויקט",
                    edit_project_description: "ערוך את המידע והסיפור של הפרויקט שלך",
                    manage_community: "ניהול קהילה",
                    manage_community_description: "נסח ופרסם עדכוני פרויקט, פרסם הערות וסמן הערות כספאם",
                    coordinate_fulfillment: "הגשמת תיאום",
                    coordinate_fulfillment_description: "הצג את פעילות הגיבוי וגישה לדוח התומכים",
                    additionalManagerDelete: `You are about to delete a Project manager invitation.<br>Please note you will be able to invite and manager your project managers during the campaign through “project management” page. Would you like to proceed?`,
                    milestoneheader1: "ישנה חשיבות רבה בהסבר של כל אבני הדרך בפרויקט גדול שמסתמך על הרבה תהליכים חיצוניים",
                    milestoneheader2: "כל שלב בפרויקט יתעדכן כך שהעוקבים שלך יידעו על ההתקדמות. יש להוסיף לפחות שלוש אבני דרך",
                    addMilestone: "הוסף אבן דרך",
                    mileStone: "אבן דרך",
                    additionalInformation: "מידע נוסף",
                    deleteMilestone: "מחק אבן דרך",
                    deleteMilestoneContent: "האם אתה בטוח שברצונך למחוק אבן דרך זו?",
                    fundingGoal: "יעד המימון",
                    fundingGoalText: "הגדר יעד ריאלי המכסה את המימון שברצונך להשיג עד סוף הקמפיין, עמלות ודמי טיפול של Medicrowd יחויבו ביעד המימון שלך. אנו ממליצים בחום להוסיף את העלויות הנוספות הללו לסכום היעד שלך. כדי לדמות את הסכום הכולל של היעד, ניתן להשתמש במחשבון המימון שלנו",
                    goalAmount: "סכום היעד",
                    calculatorText: "השתמש במחשבון שלנו כדי להעריך את העלויות הכוללות, כולל מיסים ועמלות",
                    fundingMilestone: "אבני דרך למימון",
                    fundingMilestoneText: "הראה לעוקבים ולתורמים פוטנציאליים מה העלות של כל שלב בפרויקט. אם תבחר עלות אבני דרך, היא תופיע בלשונית סטטוס פרויקט בדף הפרויקט",
                    fundingCalculator: "מחשבון מימון",
                    estimateBudget: "תקציב משוער",
                    enterAmount: "נא להזין את הסכום הכולל שלדעתך דרוש לביצוע פרויקט זה",
                    taxAdvice: "אנחנו לא יכולים לספק ייעוץ מס. פנה ליועץ מקצועי לקבלת הדרכה נוספת",
                    processingFeeText: `*Average processing fee—<span class="text-decoration-underline">this number varies</span> based on yourlocation and total number of Funders.`,
                    suggestedGoal: "יעד מוערך",
                    suggestedGoalText: "אנו מספקים הערכה זו כדי לעזור לך להגדיר את יעד המימון שלך. באחריותך לקבוע את הסכום הסופי",
                    contactEmail: `דוא"ל ליצירת קשר`,
                    projectType: "סוג פרויקט",
                    projectTypeText: `בחר "יחיד" אם אתה מגייס ומקבל כספים עבור הפרויקט הזה בשמך. בחר "עסק" אם אתה מגייס ומקבל כספים עבור הפרויקט הזה מטעם ישות שבבעלותך או שאתה מנהל בה, עם הרשאה לייצג.`,
                    projectTypeText1: `על ידי המשך, אתה מסכים ל<a :class="{'text-grey' : isSubmittedforReview}" href="javascript:void(0);">הסכם X מחובר לחשבון</a> ומסכים -Stripe לשתף את המידע שלך עימנו. לא ניתן לשנות זאת לאחר מכן.`,
                    bankAccount: "חשבון בנק",
                    bankAccountText: `הוסף את חשבון העובר ושב שאליו ברצונך לקבל כספים. חשבון זה חייב להיות ממוקם במדינה שבה נמצא הפרויקט, ויכול לקבל הפקדות ישירות במטבע שבו יגוייסו כספים לטובת הפרויקט. פרויקטים המגייסים כספים ביורו יכולים להשתמש בחשבונות בנק ב. אנחנו לא תומכים בהעברות בנקאיות, חשבונות חיסכון או חשבונות בנק וירטואליים. אתה מאשר שאתה מורשה לקשר את חשבון הבנק הזה לפרויקט זה. אם אתה מגייס כספים כאדם פרטי, החשבון רשום על שמך. אם זה מטעם עסק או מלכ"ר, החשבון רשום על שם הישות.`,
                    bankAccountText2: "יש לרשום את חשבון הבנק המקבל כספים ליחיד או לעסק המגייס כספים לפרויקט זה",
                    paymentSource: "מקור תשלום",
                    paymentSourceText: "הוסף כרטיס אשראי או חיוב של ויזה, מאסטרקארד או אמריקן אקספרס. כרטיסי Discover, JCB, Maestro ו-Visa Electron אינם מתקבלים. כרטיס זה חייב להיות שייך לאדם היחיד או הישות (או הבעלים של הישות) המגייסים כספים לפרויקט זה, ועל שמם. על ידי הוספת כרטיס זה, אני מסכימ/ה ש-Medicrowd עשויה לחייב את הכרטיס עבור החזרים כספיים, או במקרה של מחלוקות ביטול עבור הפרויקט שלי",
                    beneficiaryName: "שם המוטב",
                    credentialCorrectInfo: "ודא שכל הפרטים נכונים - לא ניתן לשנות אותם לאחר הגשת הפרויקט לבדיקה. Medicrowd אינה אחראית על אובדן העברה בנקאית כתוצאה מאישורים או חשבונות בנק שגויים או לא נתמכים",
                    bankCountry: "מדינת הבנק",
                    bankCountryInfo: "בנק זה חייב להיות ממוקם במדינת הפרויקט, ויכול לקבל הפקדות ישירות במטבע שבו מגויסים הכספים",
                    cardDetailInfo: "על ידי הוספת כרטיס זה, אני מסכימ/ה ש-Medicrowd רשאית לחייב אותו עבור החזרים כספיים, או במקרה של מחלוקות ביטול עבור הפרויקט שלי",
                    fraudText: "אם החיוב היה הונאה, נעשה בטעות, או שחנות שולחת לך פריט פגום, אתה יכול לבקש ממנפיקת הכרטיס שלך לבטל את התשלום. ודא שכל הפרטים נכונים - לא ניתן לשנות אותם לאחר הגשת הפרויקט שלך לבדיקה",
                    fraudText2: "אם החיוב היה הונאה, נעשה בטעות, או שחנות שולחת לך פריט פגום, ניתן לבקש ממנפיקת הכרטיס שלך לבטל את התשלום",
                    projectURL: "כתובת האתר של הפרויקט",
                    projectURLInfo: "נשתמש בכותרת הפרויקט הנוכחית שלך כדי ליצור כתובת URL. גם אם הכותרת תשתנה מאוחר יותר, כתובת האתר תישאר זהה. דף הפרויקט לא יעלה לאוויר עד לרגע ההשקה",
                    customReferralTags: "תגי הפניה מותאמים אישית",
                    customReferralTagsInfo: "צור תגי הפניה מותאמים אישית כדי ליצור קישורים ייחודיים הניתנים למעקב עבור יוזמות שיווקיות מחוץ ל-Medicrowd",
                    googleAnalytics: "גוגל אנליטיקס / ניתוחי גוגל",
                    googleAnalyticsInfo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                    generateTagText: 'ניתן להשתמש בכתובת האתר הזו או שניתן לערוך את החלק בכתובת האתר שלך שיעבוד מצוין עבור קידום הפרויקט העתידי שלך. נא לשים לב להלן:',
                    generateTagText1: 'בעת חיבור כתובת האתר שלך, השתמש במקפים ("-") כדי להפריד בין מילים, ו-\'/\' אינו מותר. הוא קריא יותר וידידותי למנועי חיפוש',
                    generateTagText2: "אם אפשר, כלול את מילות המפתח בכתובת האתר. זה יכול לשפר את הרלוונטיות של הדף בתוצאות החיפוש",
                    generateTagText3: 'מילות קישור הן מילים נפוצות כמו "ו", "ה", "של" וכו\', אשר מוסיפות מעט ערך לכתובת אתר',
                    generateTagText4: "חשוב לשים לב! לאחר לחיצה על כפתור האישור, אפשרות העריכה תושבת",
                    launchTheProject: "השקת הפרויקט",
                    launchTheProjectInfo: "חדשות טובות! אתה עומד להשיק את הפרויקט שלך",
                    launchTheProjectInfo1: "במהלך הפעלת הפרויקט לא ניתן יהיה לשנות את המידע הבא: קטגוריית מימון ומדינה, משך מימון, יעד מימון ופרטי תשלום",
                    launchTheProjectInfo2: "אנו ממליצים להפעיל את הבדיקה הנוספת לפני תחילת הקמפיין",
                    launchTheProjectInfo3: "מיד לאחר השקת הפרויקט תתקבל גישה לדף ניהול הפרויקט",
                    launchTheProjectInfo4: "האם להמשיך?",
                    confirmAction: "נא לאשר את הפעולה",
                    completeMilestone: "נא להשלים את אבן דרך",
                    completeMilestoneInfo: "מזל טוב! העידכון אבן הדרך הבאה הושלמה עוד רגע נשלח לעוקבים שלך. מידע זה יעודכן בדף הפרויקט שלך. האם להמשיך?",
                    dailyVisitors: "כמות הביקורים היום",
                    weeklyTotalVisits: "כמות הביקורים השבוע",
                    visitDuration: "משך ביקור",
                    dailyFunders: "כמות תורמים היום",
                    averageProjectViews: "ממוצע צפיות בפרויקט",
                    activity: "פעילות",
                    totalViewers: "סך צפיות",
                    fundersByCountry: "חלוקת תורמים לפי מדינה",
                    usersReferralTags: "כמות משתמשים בחלוקה לפי תגי הפניה",
                    projectUpdate: "עדכון פרויקט",
                    projectUpdateInfo: "עדכן את התורמים שלך לגבי מצב הפרויקט, שמור על קשר ושתף מידע מועיל כדי לשכנע אנשים נוספים להצטרף. אנו ממליצים לפרסם עדכונים שבועיים על ההתקדמות של הפרויקט וההשפעה שלו",
                    updateList: "רשימת עדכונים",
                    updateElementTitle: "עדכונים חשובים לעוקבים שלנו!",
                    deleteDraftContent: "האם להמשיך?",
                    deleteUpdateHeader: "מחק את עדכון הפרויקט",
                    deleteUpdateInfo: "האם למחוק את עדכון הפרויקט?",
                    editUpdateInfo: "עריכת עדכונים חשובים עבור עוקבי הפרויקט",
                    deleteProjectManager: "מחק את מנהל הפרויקט",
                    deleteProjectManagerInfo: 'ההזמנה למנהל פרויקט תימחק.<br>נא לשים לב שניתן להזמין ולנהל את מנהלי הפרויקטים במהלך הקמפיין דרך עמוד "ניהול פרויקטים". האם להמשיך?',
                    deleteTeamUser: "מחיקת חבר צוות",
                    deleteTeamUserInfo: "אתה עומד למחוק מידע של חבר צוות. <br>משמעות הדבר היא שתורמים פוטנציאליים לא יראו מידע זה בדף הפרויקט.<br> האם להמשיך?",
                    raised: "גויס",
                    backers: "תומכים",
                    daysToGo: "ימים שנותרו",
                    projectCampaignInfo: "קמפיין הפרויקט הזה הסתיים. אבל אם ברצונך לעזור לפרויקט המדהים הזה להצליח, עדיין ניתן לממן אותו",
                    campaign: "קמפיין",
                    teamUpdates: "עדכוני צוות",
                    comments: "הערות",
                    risks: "סיכונים",
                    overview: "סקירה כללית",
                    overviewInfo: "ספר/י לנו על הפרויקט, איך הכל התחיל?",
                    overviewInfo2: "היו כנים לגבי הסיכונים והאתגרים הפוטנציאליים של פרויקט זה <br /> וכיצד אתם מתכננים להתגבר עליהם כדי להשלים אותו",
                    aboutInfo: "ספר/י על הצוות שלך ועל הניסיון המקצועי שלהם",
                    mileStoneInfo: "לפרויקט שלך אין מידע על אבני דרך. חשוב לוודא שתורמים פוטנציאליים מודעים לתהליך הפרויקט על ידי עדכון שוטף",
                    notifyEmail: "שלח דואר אלקטרוני למשתמש לגבי בקשה משנית",
                    notifyWebsite: "הודע למשתמש באמצעות הודעת תצוגה מקדימה של אתר",
                    chooseAmountFunding: "בחר את הסכום עבור המימון שלך",
                    totalAmount: "הכמות הכוללת",
                    addPaymentMethod: "הוסף אמצעי תשלום חדש",
                    saveCreditDetail: "שמור את פרטי כרטיס האשראי",
                    pleaseNote: "שימו לב",
                    backingInfo: "גיבוי פירושו תמיכה בפרויקט יצירתי, ללא קשר לתוצאה",
                    agreeCheck: "אני מבין שתגמולים או החזרים אינם מובטחים על ידי Medicrowd או היוצר",
                    leavingProject: "האם ברצונך לעזוב?",
                    leavingProjectInfo: "אם החלטת לעזוב, ההתקדמות שלך לא תישמר. ניתן לשמור את הפרויקט כטיוטה ולחזור כדי לסיים אותו מאוחר יותר",
                    warning: "אזהרה",
                    warningInfo: "ראשית עליך להשלים את השלב הבסיסי של הפרויקט!",
                    submitReviewProject: "שליחת הפרויקט לביקורת",
                    submitReviewProjectInfo: `<span>הפרויקט הזה נשלח לבדיקה. ייתכן שייקח לצוות שלנו מספר ימים כדי לוודא שהפרויקט שלך עומד בכללים וההנחיות שלנו, ושחשבון הבנק שלך מקושר. במהלך הסקירה ניתן להוסיף את הסעיפים הבאים של הפרויקט: בסיסי, סיפור, צוות, אבני דרך וקידום. </span><br><span> אנו ממליצים למלא את פרטי המבצע לאחר קבלת אישור ההשקה.</span><br><span>שים לב שאינך יכול לשנות מימון ותשלום לאחר הגשה לבדיקה. האם את/ה בטוח/ה שברצונך להמשיך?</span>`,
                    meetOurTeam: "הכירו את הצוות שלנו",
                    ourCompany: "החברה שלנו",
                    youDidIt: "עשית את זה!",
                    youDidItInfo: "הפרויקט באוויר! מזל טוב! אנו מאחלים לך בהצלחה! על ידי שימוש בדף ניהול פרויקטים, ניתן לנהל מידע על הפרויקט, לעדכן אבני דרך ולתקשר עם העוקבים והתורמים הפוטנציאליים שלך. לחלופין, ניתן לגשת אליו מאוחר יותר על ידי לחיצה על פרויקט זה ברשימת הפרויקטים שלך",
                    imageInfo: "ניתן לשחרר תמונה כאן, או לבחור קובץ. זה חייב להיות JPG, PNG, GIF או BMP, לא יותר מ-10 MB",
                    videoInfo: "ניתן לשחרר כאן סרטון, או לבחור קובץ. זה חייב להיות MOV, MPEG, AVI, MP4, 3GP, WMV או FLV, לא יותר מ-20 MB",
                    medicrowdFee: "עמלת Medicrowd",
                    processingFee: "עמלת עיבוד",
                    processingInfo: `*עמלת עיבוד ממוצעת— <span class="text-decoration-underline">מספר זה משתנה</span> על סמך המיקום שלך ומספר התורמים הכולל.`,
                    bankAccountMessage: "השלם את השלבים לעיל כדי לבטל את הנעילה של סעיף זה",
                    bankName: "שם הבנק",
                    expires: "פג תוקף",
                    spamComment: "הערת דואר זבל",
                    description: "תיאור:",
                    projectUrlInfo: "כתובת האתר תתקבל לאחר בדיקה",
                    insertLabel: "הכנס תווית",
                    trackingId: "מזהה מעקב",
                    reply: "להשיב",
                    spam: "ספאם",
                    peopleFunded: "אנשים ממומנים",
                    target: "יעד",
                    commentInfo: "רק תומכים יכולים לכתוב תגובות",
                    addCommentText: "הוסף תגובה…",
                    of: "שֶׁל",
                    projectCategory: "קטגוריית פרויקטים",
                    projectSubtitle: "כותרת המשנה לפרויקט - הוסף הסבר קצר לגבי הנושא למחקר שלך ולמה זה חשוב",
                    by: "על ידי"
                }
            }
        }
    }
}
