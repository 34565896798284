<template>
    <footer class="row m-0 base-footer text-white">
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <ul class="nav flex-column">
                        <router-link
                            :to="{ name: 'home' }"
                            class="d-flex align-items-center mb-3 link-dark text-decoration-none"
                        >
                            <img src="../assets/img/medicrowd_logo_wh.svg" alt="Logo" class="footer-logo" />
                        </router-link>
                        <a href="" class="text-white fs-3 text-decoration-none footer-connect"
                        >{{translateLanguage('footer.connectUs')}}
                            <svg class="icon icon-long-arrow ms-5" width="39.5" height="14">
                                <use href="../assets/img/icons.svg#icon-long-arrow"></use>
                            </svg>
                        </a>
                    </ul>
                </div>
                <div class="col-3 mt-2 mt-md-0 mt-lg-0 footer-column">
                    <ul class="nav flex-column">
                        <h5 class="fw-normal">Medicrowd</h5>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.updates')}}</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.pricing')}}</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.tutorials')}}</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.teams')}}</a></li>
                    </ul>
                </div>
                <div class="col-3 footer-column">
                    <h5 class="fw-normal">{{translateLanguage('footer.resources')}}</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.examples')}}</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.sessions')}}</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.program')}}</a></li>
                    </ul>
                </div>
                <div class="col-3 about-col footer-column">
                    <h5 class="fw-normal">{{translateLanguage('footer.about')}}</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.community')}}</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.company')}}</a></li>
                        <li class="nav-item"><a href="#" class="nav-link p-0 lh-lg">{{translateLanguage('footer.careers')}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mt-60 d-none d-md-flex">
            <!--      <p class="fs-18 mt-auto mb-0">Copyright Element 2023</p>-->
        </div>
        <div class="col-12 col-md-6 mt-60">
            <p class="fs-18 mt-auto mb-0 text-right">{{translateLanguage('footer.copyRight')}}</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/components/footer.scss';
</style>
