import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getAuthUser, getStorage, removeStorage, setStorage } from '@/Util/auth';

Vue.use(Vuex);

const FORM_STATE = {
    basic: null,
    team: null,
    story: null,
    milestone: null,
    funding: null,
    payment: null
};

const state = {
    user: null,
    actionName: null,
    project: null,
    projectItem: null,
    setActiveTab: null,
    getActiveTab: null,
    getProjectId: null,
    setProjectId: null,
    loader: false,
    getProjectPreviewItem: { ...FORM_STATE },
    projectLaunchSuccess: false,
    isProjectChange: false,
    isProjectSaveError: false,
    isProjectSavePopup: 0, // this is only use for account dropdown
    isProjectChangePreview: false,
    exitPreviewRoute: null,
    skipSaveProjectAlert: false,
    isUnAuthorized: false,
    defaultLanguage:"en"
};

const store = new Vuex.Store({
    state,
    getters: {
        user: (state) => {
            return ( state.user && !_.isEmpty(getAuthUser()) ? state.user : null );
        },
        actionName: (state) => {
            return state.actionName;
        },
        project: (state) => {
            return state.project;
        },
        projectItem: (state) => {
            return state.projectItem;
        },
        getActiveTab: (state) => {
            return state.getActiveTab;
        },
        setActiveTab: (state) => {
            return state.setActiveTab;
        },
        getProjectId: (state) => {
            return state.getProjectId;
        },
        setProjectId: (state) => {
            return state.setProjectId;
        },
        setLoader: (state) => {
            return state.loader;
        },
        getLoader: (state) => {
            return state.loader;
        },
        isProjectChange: (state) => {
            return state.isProjectChange;
        },
        isProjectSaveError: (state) => {
            return state.isProjectSaveError;
        },
        getProjectPreviewItem: (state) => {
            return state.getProjectPreviewItem;
        },
        getProjectLaunchSuccess: (state) => {
            return state.projectLaunchSuccess;
        },
        isProjectSavePopup: (state) => {
            return state.isProjectSavePopup;
        },
        isProjectChangePreview: (state) => {
            return state.isProjectChangePreview;
        },
        getExitPreviewRoute: (state) => {
            return state.exitPreviewRoute;
        },
        getSkipSaveProjectAlert: (state) => {
            return state.skipSaveProjectAlert;
        },
        getUnAuthorized: (state) => {
            return state.isUnAuthorized;
        },
        defaultLanguage:(state)=>{
            return state.defaultLanguage;
        }
    },
    actions: {
        user: (context, user) => {
            context.commit('user', user);
        },
        actionName: (context, actionName) => {
            context.commit('actionName', actionName);
        },
        project: (context, project) => {
            context.commit('project', project);
        },
        projectItem: (context, project) => {
            context.commit('project', project);
        },
        setActiveTab: (context, tab) => {
            context.commit('setActiveTab', tab);
        },
        setProjectId: (context, id) => {
            context.commit('setProjectId', id);
        },
        setLoader: (context, data) => {
            context.commit('SET_LOADER', data);
        },
        setProjectOldItem: (context, data) => {
            if ( data == null ) {
                context.commit('SET_PROJECT_LOAD_ITEM', { ...FORM_STATE });
            }
        },
        setProjectLaunchSuccess: (context, data) => {
            context.commit('setProjectLaunchSuccess', data);
        },
        setIsProjectChange: (context, data) => {
            context.commit('SET_IS_PROJECT_CHANGE', data);
        },
        setIsProjectSaveError: (context, data) => {
            context.commit('SET_IS_PROJECT_SAVE_ERROR', data);
        },
        setIsProjectSavePopup: (context, data) => {
            context.commit('SET_IS_PROJECT_SAVE_POPUP', data);
        },
        setIsProjectChangePreview: (context, data) => {
            context.commit('SET_IS_PROJECT_CHANGE_PREVIEW', data);
        },
        setExitPreviewRoute: (context, data) => {
            context.commit('SET_EXIT_PREVIEW_ROUTE', data);
        },
        setSkipSaveProjectAlert: (context, data) => {
            context.commit('SET_SKIP_SAVE_PROJECT_ALERT', data);
        },
        setUnAuthorized: (context, data) => {
            context.commit('SET_UNAUTHORIZED', data);
        },
        updateTeam: (context, data) => {
            context.commit('updateTeam', data);
        },
        updateBasic: (context, data) => {
            context.commit('updateBasic', data);
        },
        updateStory: (context, data) => {
            context.commit('updateStory', data);
        },
        updateMilestone: (context, data) => {
            context.commit('updateMilestone', data);
        },
        updateFunding: (context, data) => {
            context.commit('updateFunding', data);
        },
        updatePayment: (context, data) => {
            context.commit('updatePayment', data);
        },
        updateDefaultLanguage: (context, data) => {
            context.commit('updateDefaultLanguage', data);
        }
    },
    mutations: {
        user: (state, user) => {
            state.user = user;
            if ( state.user === null ) {
                removeStorage('auth');
            }
        },
        actionName: (state, actionName) => {
            state.actionName = actionName;
        },
        project: (state, project) => {
            state.project = project;
        },
        projectItem: (state, project) => {
            state.projectItem = project;
        },
        setActiveTab: (state, tab) => {
            state.getActiveTab = tab;
            setStorage('active-tab', tab);
        },
        setProjectId: (state, id) => {
            state.getProjectId = id;
            setStorage('project-id', id);
        },
        SET_LOADER: (state, data) => {
            state.loader = data;
        },
        SET_PROJECT_LOAD_ITEM: (state, data) => {
            state.getProjectPreviewItem = data;
        },
        setProjectLaunchSuccess: (state, data) => {
            state.projectLaunchSuccess = data;
        },
        SET_IS_PROJECT_CHANGE: (state, data) => { //use if project basic submit and title change then update account dropdown project title also
            state.isProjectChange = data;
        },
        SET_IS_PROJECT_SAVE_ERROR: (state, data) => { //use if project alert confirmation box of project (save|leave)
            state.isProjectSaveError = data;
        },
        SET_IS_PROJECT_SAVE_POPUP: (state, data) => { //use if project alert confirmation box of project (save|leave)
            if ( data == true ) {
                state.isProjectSavePopup += 1;
            } else {
                state.isProjectSavePopup = 0;
            }
        },
        SET_IS_PROJECT_CHANGE_PREVIEW: (state, data) => { //use if project alert confirmation box of project (save|leave)
            state.isProjectChangePreview = data;
        },
        SET_EXIT_PREVIEW_ROUTE: (state, data) => {
            state.exitPreviewRoute = data;
        },
        SET_SKIP_SAVE_PROJECT_ALERT: (state, data) => {
            state.skipSaveProjectAlert = data;
        },
        SET_UNAUTHORIZED: (state, data) => {
            state.isUnAuthorized = data;
        },
        updateTeam(state, newTeam) {
            state.getProjectPreviewItem.team = newTeam;
        },
        updateBasic(state, newBasic) {
            state.getProjectPreviewItem.basic = newBasic;
        },
        updateStory(state, newStory) {
            state.getProjectPreviewItem.story = newStory;
        },
        updateMilestone(state, newMilestone) {
            state.getProjectPreviewItem.milestone = newMilestone;
        },
        updateFunding(state, newFunding) {
            state.getProjectPreviewItem.funding = newFunding;
        },
        updatePayment(state, newPayment) {
            state.getProjectPreviewItem.payment = newPayment;
        },
        updateDefaultLanguage(state, newLanguage) {
            state.defaultLanguage = newLanguage;
        }
    },
    plugins: [createPersistedState()]
});

export default store;
