export default {
    bind(el, binding, vnode) {
        function handleInputChange(event) {
            let inputValue = event.target.value.trim();
            inputValue = inputValue.replace(/(^|[^\d.])0+(\d+)/g, '$1$2');
            inputValue = inputValue.replace(/(\d)0/g, '$1');
            if ( !isNaN(parseFloat(inputValue)) ) {
                vnode.context[binding.expression] = inputValue;
            }
        }

        function handleOnBlue(event) {
            let inputValue = event.target.value.trim();
            inputValue = inputValue.replace(/^0+/, '');
            if ( inputValue.startsWith('.') ) {
                inputValue = '0' + inputValue;
            }
            event.target.value = inputValue;
        }

        function handleKeypress(event) {
            const key = event.key;
            const allowedCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
            if ( !allowedCharacters.includes(key) || event.target.value.length > 14 ) {
                event.preventDefault();
            }
        }

        el.addEventListener('input', handleInputChange);
        el.addEventListener('blur', handleOnBlue);
        el.addEventListener('keypress', handleKeypress);
    }
};
