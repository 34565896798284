import axios from 'axios';
import moment from 'moment-timezone';
import serverConfig from "../../server.config.json";
import { getAuthUser, hasAuthUser, refreshToHome, removeStorage } from "./auth";
import Vue from 'vue';

/**
 * Create an Axios Client with defaults
 */
let axiosInstance = axios.create();

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Content-Language'] = localStorage.getItem(`${ serverConfig.storagePrefix }.settings.locale`) || 'en-US';

export const client = axiosInstance;

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options) {

    let isAdmin = false;

    if ( hasAuthUser() && getAuthUser() ) {
        const user = getAuthUser();

        if ( user.role === 'admin' ) {
            isAdmin = true;
        }

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${ user.token }`;
        axiosInstance.defaults.headers.common['Content-Timezone'] = moment.tz.guess();
        axiosInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    }

    const onSuccess = function (response) {
        return response;
    };

    const onError = function (error) {
        if ( error.response ) {
            if ( error.response.status === 403 ) {
                window.location.href = '/';
            }
            if ( error.response.status === 401 ) {
                setTimeout(() => {
                    removeStorage('auth');
                    window.location.href = isAdmin ? '/admin/login' : '/login';
                }, 2000);

                return false;
            }
        } else {
        }

        return Promise.reject(error.response || error.message);
    };

    return axiosInstance(options)
    .then(onSuccess)
    .catch(onError);
};
