<template>
    <div class="admin-dashboard">
        <div class="container-fluid">
            <div class="row">
                <admin-sidebar :isSidebarOpen="isSidebarOpen" @updateToggle="toggleSidebar"></admin-sidebar>
                <main
                    :class="[
                        '`p-0`',
                        { 'col-md-9 col-lg-10': isSidebarOpen },
                        { 'col-md-12 col-lg-12': !isSidebarOpen },
                      ]"
                >
                    <admin-topbar v-on:toggle-sidebar="toggleSidebar" :isSidebarOpen="isSidebarOpen"></admin-topbar>
                    <div id="main-content" class="p-4">
                        <nav aria-label="breadcrumb" v-if="$route.name !== 'Dashboard' && $route.name !== 'AdminProjectForm' && $route.name !== 'AdminProjectApprovedForm' && $route.name !== 'AdminUserForm'" >
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link :to="{name:'Dashboard'}"
                                                 :class="{'active' : $route.name == 'Dashboard'}">Dashboard
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link :to="{name:$route.name}"
                                                 :class="{'active' : $route.name != 'Dashboard'}">{{
                                            ( $route.meta || {} ).breadcrumbs
                                        }}
                                    </router-link>
                                </li>
                            </ol>
                        </nav>
                        <router-view />
                    </div>
                    <admin-footer></admin-footer>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import AdminFooter from "./footer";
import AdminSidebar from "./sidebar";
import AdminTopbar from "./topbar";

export default {
    components: {
        AdminFooter,
        AdminSidebar,
        AdminTopbar
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    data() {
        return {
            isSidebarOpen: (window.innerWidth <= 768) ? false : true
        };
    },
    methods: {
        handleWindowResize() {
            var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
            if (width <= 768){
                this.isSidebarOpen = false;
            } else {
                this.isSidebarOpen = true;
            }
        },
        toggleSidebar(isSidebarOpen) {
            this.isSidebarOpen = isSidebarOpen;
            // this.$refs['sidebar'].toggleSidebar(isSidebarOpen);
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/components/admin.scss";
</style>
