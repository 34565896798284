<template>
    <footer class="d-flex justify-content-between">
        <span>Copyright © 2023-2024 <a href="">Medicrowd</a></span>
        <ul class="nav m-0">
            <li class="nav-item">
                <a class="nav-link text-secondary" aria-current="page" href="#">Privacy Policy</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary" href="#">Terms and conditions</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary" href="#">Contact</a>
            </li>
        </ul>
    </footer>
</template>

<script>
export default {
    name: "AdminFooter"
};
</script>
