<template>
  <span class="d-flex align-items-center badge status-badge" :name="data.status.className">
    {{ data.status.name }}
  </span>
</template>

<script>
export default {
    name: 'StatusBadge',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.status-badge.badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 16px;
    height: 32px;
    border-radius: 50px;
    width: fit-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.83069px;
    color: #424242;

    &[name='Ongoing'] {
        background: #d8d0ff;
    }

    &[name='Successfull'] {
        background: #dcffcb;
    }

    &[name='Failed'] {
        background: #ffebeb;
    }

    &[name='draft'] {
        color: #424242;
        background: #f5f5f5;
        border: 1px solid #c2c2c2;
    }

    &[name='review'] {
        color: #e0ce2c;
        background: #fefadc;
        border: 1px solid #e0ce2c;
    }

    &[name='approved'] {
        color: #21725e;
        background: #e7faf5;
        border: 1px solid #b5d0c9;
    }

    &[name='launched'] {
        color: #5433ff;
        background: #d8d0ff;
        border: 1px solid #5e47d2;
    }

    &[name='on_going_review'] {
        color: #5b670d;
        background: #fefadc;
        border: 1px solid #5b670d;
    }

    &[name='cancelled'] {
        background: #ffebeb;
        border: 1px solid #ffebeb;
    }

    &[name='on_hold'] {
        background: #ffcc00;
        border: 1px solid #ffcc00;
    }
    &[name='suspend'] {
        background: #ffcc00;
        border: 1px solid #ffcc00;
    }
}
</style>
