<template>
    <div>
    <nav class="navbar navbar-light bg-light p-3">
        <div class="d-flex col-12 col-md-3 col-lg-2 mb-2 mb-lg-0 flex-wrap flex-md-nowrap justify-content-between">
            <button class="btn btn-primary" @click="toggleSidebar">
                <span v-if="isSidebarOpen" class="bi bi-chevron-left"></span>
                <span v-else class="bi bi-chevron-right"></span>
            </button>
            <button class="navbar-toggler d-md-none collapsed mb-3" type="button" data-toggle="collapse"
                    data-target="#sidebar" aria-controls="sidebar" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <!--        <div class="col-12 col-md-4 col-lg-2">-->
        <!--            <input class="form-control form-control-dark" type="text" placeholder="Search" aria-label="Search">-->
        <!--        </div>-->
        <div class="col-12 col-md-5 col-lg-8 d-flex align-items-center justify-content-md-end mt-3 mt-md-0">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       id="topbar-login-data">
                        <span class="me-2 mt-2 d-none d-lg-inline text-gray-600 small">  Hello, {{
                                ( user || {} ).name
                            }}</span>
                        <div v-if="user && user.image && user.image.path">
                            <img v-if="user.image && user.image.path" :src="user.image.path"
                                 class="img-thumbnail rounded-circle"
                                 width="40" height="40" />
                        </div>
                        <div v-else class="box image rounded-circle">
                            <span data-v-db0fbf44="" class="title">{{user?.name ? user.name.charAt(0).toUpperCase() : 'NA'}}</span>
                        </div>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end position-absolute">
                        <li>
                            <router-link class="dropdown-item" :to="{name:'AdminUserProfile'}">
                                Profile
                            </router-link>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="dropdown-item" @click.prevent="handleSubmitLogout">Logout</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
        <logoutConfirmed ref="logoutConfirmed"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { removeStorage } from "@/Util/auth";
import logoutConfirmed from './modal/logoutConfirmed'

export default {
    data() {
        return {
            // isSidebarOpen: true
        };
    },
    components: { logoutConfirmed },
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar', !this.isSidebarOpen);
        },
        async handleSubmitLogout() {
            this.$refs.logoutConfirmed.logoutShowModal();
        },
    },
    props: {
        isSidebarOpen: Boolean,
    },
    computed: {
        ...mapGetters(["user"]),
    },
};
</script>

<style scoped>
.rounded-circle {
    height: 40px !important;
    width: 40px !important;
}

@media (max-width: 767.98px) {
    .rounded-circle {
        height: 30px !important;
        width: 30px !important;
    }

    .image.rounded-circle > .title {
            font-size: 20px !important;
    }
}
</style>
