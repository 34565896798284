<template>
    <div class="my-2 border-25">
        <span v-if="status == 'draft'" class="bg-dark text-white p-2 lh-lg ">{{ _.capitalize(status) }}</span>
        <span v-if="status == 'review'"
              class="bg-warning text-white p-2 lh-lg">{{
                this.$route.name == 'AdminUserForm' ? 'Submitted for Review' : 'Pending'
            }} </span>
        <span v-if="status == 'approved'" class="bg-info text-white p-2 lh-lg"> Approved For Launch </span>
        <span v-if="status == 'launched' && remainingdays >0"
              class="bg-primary text-white p-2 lh-lg"> {{
                this.flag ? 'Launched' : 'Ongoing Campaign'
            }}</span>
        <span v-if="status == 'launched' && remainingdays <= 0"
              class="bg-danger text-white p-2 lh-lg">  {{
                this.flag ? 'Completed' : 'Campaign Ended'
            }}</span>
        <span v-if="status == 'on_going_review'" class="bg-warning text-white p-2 lh-lg">Ongoing Review</span>
        <span v-if="status == 'on_hold'"
              class="bg-warning text-white p-2 lh-lg">{{ _.capitalize(status.replaceAll(/_/g, " ")) }}</span>
        <span v-if="status == 'cancelled'" class="bg-danger text-white p-2 lh-lg">Cancelled</span>
        <span v-if="status == 'suspend'" class="bg-danger text-white p-2 lh-lg">Suspend Campaign</span>
    </div>
</template>

<script>
export default {
    props: {
        status: {
            required: true
        },
        remainingdays: {
            required: false,
            default() {
                return 0;
            }
        },
        flag: {
            required: false,
            default() {
                return 0;
            }
        }
    },
    data() {
        return {};
    },
};
</script>
<style>
.border-25 .lh-lg {
    border-radius: 25px;
}

.border-25 {
    width: 180px;
}
</style>
