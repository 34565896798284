<template>
    <div
        class="modal fade"
        id="logoutConfirmed"
        tabindex="-1"
        aria-hidden="true"
        ref="logoutConfirmedModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body px-4 py-3 row">
                    <div class="row col-12 p-0 mt-0 mx-0 d-flex align-items-center mb-3">
                        <h5 class="col fw-bold m-0">Logout</h5>
                        <button
                            ref="close-modal"
                            type="button"
                            class="rounded btn-close opacity-1 align-top align-self-start ms-auto col-auto me-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="row col-12 p-0 m-0">
                        <div class="col-12 mb-3">
                            <p class="text-secondary fs-18 mb-0">
                                Are you sure, you want to logout?
                            </p>
                        </div>
                        <div class="col-12 d-flex justify-content-end gap-4">
                            <button
                                data-bs-dismiss="modal"
                                type="button"
                                class="btn main-btn d-block fs-20 px-3 col sec-btn h-44"
                            >
                                Cancel
                            </button>
                            <button class="main-btn col fs-20 h-44" @click="logout()">Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {removeStorage} from "@/Util/auth";

export default {
    data() {
        return {
            shoModal: false,
            modal: null
        };
    },
    mounted(){
        const modalElem = this.$refs.logoutConfirmedModal;
        this.modal = new window.bootstrap.Modal(modalElem);
    },
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        logoutShowModal() {
            this.showModal =  this.modal.show();
        },
        logoutHideModal() {
            this.showModal =  this.modal.hide();
        },
        logout(){
            this.logoutHideModal();
            removeStorage('auth');
            this.$store.dispatch('user', null);
            this.$router.push({ name: 'AdminLogin' });
        }
    }
};
</script>

