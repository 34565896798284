import en from './locales/en.js'
import he from './locales/he.js'
import {EventBus} from '@/event-bus';
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            languages: [{
                name: 'en',
                image: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg',
            },
                {
                    name: 'he',
                    image: 'https://cdn.britannica.com/53/1753-004-03582EDA/Flag-Israel.jpg',
                },
            ],
        }
    },
    mixins: [en, he],

    created() {
        // EventBus.$on('languageChanged', (newLang) => {
        //     this.$set(this, 'defaultLanguage', newLang);
        // });
    },
    computed: {
        ...mapGetters(['defaultLanguage']),
    },
    methods: {
        translateLanguage(prop) {
            const props = prop.split('.')
            let value = this[this.defaultLanguage];
            for (const p of props) {
                if (value && value[p]) {
                    value = value[p];
                } else {
                    return undefined;
                }
            }
            return value;
        },
    },
};
