export default {
    data() {
        return {
            en: {
                menu: {
                    howItsWork: {
                        heading: 'How it Works',
                        title: 'The Benefits we provide',
                        large_enough: 'Shine the light on orphan diseases that lack of a market large enough to gain support and resources.',
                        medical: 'Help fund and boost medical research for finding cures.',
                        contribute: 'Let people with conditions and their families to contribute to discovering solutions.',
                        difference: 'Make a real difference.'
                    },
                    logIn: 'Log in',
                    news: 'News',
                    ourStory: 'Our Story',
                    browseProjects: {
                        title: 'Browse Projects',
                        show_all: 'Show All',
                        Drug_discovery_research: 'Drug discovery research',
                        Invention_of_medical_nursing_facility_aid_devices_utilities: 'Invention of medical/nursing facility aid devices/utilities',
                        Innovative_alternative_treatment: 'Innovative alternative treatment',
                        Development_of_nutritional_supplements: 'Development of nutritional supplements',
                        projects:"Projects",
                    },
                    startAProject: 'Start a Project'
                },
                accountMenu: {
                    yourAccount: "Your Account",
                    profile: "Profile",
                    settings: "Settings",
                    favouriteProjects: "Favourite Projects",
                    recommendedForYou: "Recommended for you",
                    new: "New",
                    viewAll: "View all",
                    logOut: "Log Out",
                    yourProject: "Your Projects",
                    logoutMessage:"Are you sure, you want to logout?"
                },
                search: 'Search',
                name:"Name",
                homePage: {
                    header: "Funding cures, changing lives",
                    content_1: "Our company is dedicated to helping ill people connect with researchers to find a cure for their disease. We believe that everyone deserves access to the best medical research and treatments, and that by working together, we can make a real difference in the fight against illness.",
                    getStartedBtn: "Get Started",
                    popularProjects: "Popular Projects",
                    popularProjectContent: "Currently There's No Popular Project",
                    readyToJoin: "Ready To Join ?",
                    receiveNews: "Receive the latest news and new campaigns",
                    yourEmailAddress: "Your email address",
                    spamMessage: "Don't worry, we only send the best material - no spam.",

                },
                footer: {
                    connectUs: "Connect with us",
                    updates: "Updates",
                    pricing: "Pricing",
                    tutorials: "Tutorials",
                    teams: "Teams",
                    resources: "Resources",
                    examples: "Examples",
                    sessions: "Sessions",
                    program: "Program",
                    about: "About",
                    community: "Community",
                    company: "Company",
                    careers: "Careers",
                    copyRight: "Designed by Checker Software",
                },
                newsPage:{
                    learnMore: "Learn more",
                },
                ourStory:{
                    header:"Our Story",
                    content:"During a physical exam, your doctor will press on your or your child's abdomen to check the size of the spleen and liver. To determine if your child has Gaucher disease, the doctor will compare your child's height and weight to standardized growth charts. He or she might also recommend certain lab tests, imaging scans and genetic counseling.",

                },
                profile:{
                    about:"About",
                    myCredentials:"My Credentials",
                    shortBio: "Short Bio",
                    education: "Education",
                    experience:"Experience",
                    myProjects:"My Projects",
                    similarProject:"Similar Projects",
                    editCredentials:"Edit Credentials",
                    addCredentials:"Add Credentials",
                    myWebsite:"My Website",
                    editWebsite:"Edit Website",
                    addWebsite:"Add Website",
                    myLinkedinPage:"My Linkedin Page",
                    editLinkedin:"Edit Linkedin",
                    addLinkedin:"Add Linkedin",
                    backed:"Backed",
                    backedProjects:"Backed Projects",
                    newProject:"New Project",
                    tellExperience:"Tell the people about your experience",
                    showWebsite:"Show people your website",
                    showLinkedIn:"Show people your linkedin",
                    credentialInfo:"Your information will be visible to everyone who visits the project page. Please note, Medicrowd team will perform the background check as part of approving the funding campaign for the project.",
                    deleteProject:"Delete Project",
                    deleteProjectLine1:"You are about to delete the project.",
                    deleteProjectLine2:"Once deleted, all information related to this project will be lost and cannot be recovered",
                    deleteProjectLine3:"Are you sure you want to proceed with deleting the project?",

                },
                btn:{
                    save:"Save",
                    cancel:"Cancel",
                    edit:"Edit",
                    delete:"Delete",
                    leave:"Leave",
                    next:"Next",
                    saveChanges:"Save Changes",
                    sendAgain:"Send again",
                    updateEmailAddress:"Update Email Address",
                    upload:"Upload",
                    resetPassword:"Reset Password",
                    setPassword:"Set Password",
                    goBack:"Go back",
                    savePassword:"Save Password",
                    preview:"Preview",
                    backBasis:"Back To Basics",
                    backStory:"Back To Story",
                    add:"Add",
                    sendInvite:"Send Invitation",
                    backTeam:"Back To Team",
                    backMilestone:"Back To Milestones",
                    select:"Select",
                    addBankAccount:"Add Bank Account",
                    editBankAccount:"Edit Bank Account",
                    backFunding:"Back To Funding",
                    submitReview:"Submit for Review",
                    generateTag:"Generate Tag",
                    backPayment:"Back To Payment",
                    launchProject:"Launch project",
                    launch:"Launch",
                    generateUrl:"Generate My Project URL",
                    confirm:"Confirm",
                    backUpdate:"Back to Updates",
                    backProjectStatus:"Back to Project Status",
                    backBackerReport:"Back to Backer Report",
                    saveDraft:"Save as Draft",
                    postUpdate:"Post Update",
                    deleteUpdate:"Delete Update",
                    backStatistics:"Back To Statistics",
                    deleteDraft:"Delete Draft",
                    fundThisProject:"Fund this project",
                    addToFavorites:"Add to favorites",
                    suspendUpdate:"Suspend Update",
                    readMore:"Read More",
                    readLess:"Read Less",
                    reasonForSuspend:"Reason for Suspend",
                    send:"Send",
                    saveProject:"Save Project",
                    submitLater:"Submit Later",
                    submit:"Submit",
                    exitPreview:"Exit Preview",
                    backToHomeScreen:"Back to Home Screen",
                    projectPage:"Project Page",
                },
                chart: {
                    funders:"Funders",
                    addedToFavorites:"Added to Favorites",
                    occasionalUsers:"Occasional Users",
                    totalCount:"Total Count"
                },
                settings: {
                    title:"Settings",
                    account:"Account",
                    editProfile:"Edit Profile",
                    paymentMethod:"Payment Method",
                    email:"Email",
                    addEmail:"Add Email",
                    addName:"Add Name",
                    newEmail:"Enter New Email",
                    emailNote:"Please note that changing your email address will require additional verification.",
                    changeEmail:"Change Email",
                    password:"Password",
                    changePassword:"Change Password",
                    changeYourPassword:"Change Your Password",
                    currentPassword:"Current Password",
                    newPassword:"New Password",
                    confirmNewPassword:"Confirm New Password",
                    updateYourPassword:"Update Your password",
                    address:"Address",
                    street:"Street",
                    city:"City",
                    country:"Country",
                    creditCardNumber:"Credit Card Number",
                    expiration:"Expiration",
                    cvc:"CVC",
                    holdersName:"Holder’s Name",
                    editCreditCard:"Edit Credit Card",
                    addCreditCard:"Add Credit Card",
                    verifyEmail:"Verify Your Email",
                    notGetCode:"Didn’t get a code?",
                    sentCodeMessage:"We sent a 4-digit verification code to ",
                    passwordMessage:"Minimum of 8 characters",
                    profileChange:"Change Profile Picture",
                    update:"Update",
                },
                login:{
                    signIn:"Sign In",
                    logIn:"Log In",
                    rememberMe:"Remember me",
                    forgotPassword:"Forgot Your Password ?",
                    signUp:"Sign Up",
                    forgotPasswordContent:"Enter your email address and we’ll send you a link to reset your password",
                    resetPasswordContent: "Reset password link has been sent to your email successfully.",
                    setNewPassword:"Set New Password",
                    confirmPassword:"Confirm Password",
                    otp:"otp",
                    pass:"pass",
                    wrongEmailContent:"Wrong email address?",
                    choosePassword:"Choose Password",
                    alreadyAccount:"Already Have an account?",
                    newUser:"New To Medicrowd?"
                },
                projectPage:{
                    basic:"Basic",
                    story:"Story",
                    team:"Team",
                    milestones:"Milestones",
                    funding:"Funding",
                    payment:"Payment",
                    promotion:"Promotion",
                    projectStatus:"Project Status",
                    backerReport:"Backer Report",
                    statistics:"Statistics",
                    updates:"Updates",
                    primaryCategory:"Primary Category",
                    primaryCategoryPlaceholder:"Select Primary Category",
                    countryOfOrigin:"Country of Origin",
                    countryOfOriginPlaceholder:"Select Your Country",
                    projectTitle:"Project Title",
                    projectTitleContent:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    title:"Title",
                    subTitle:"Subtitle",
                    projectImage:"Project Image",
                    projectImageContent:"Add an image that clearly represents your project. Choose one that looks good at different sizes—it’ll appear on your project page, across the Medicrowd website and (when shared) on social channels.",
                    projectImageContent1:"Your image should be at least 1024x576 pixels. It will be cropped to a 16:9 ratio.",
                    projectRadio:"Use it as a main banner in project page",
                    projectVideo:"Project Video",
                    projectVideoContent:"Add a video that describes your project. Tell people what you’re raising funds to do, how you plan to make it happen, who you are, and why you care about this project.",
                    fundingDuration:"Funding Duration",
                    fundingDurationOption:"Please choose the most preferable option",
                    fixedNumberDays:"Fixed Number of Days",
                    fixedNumberText:"Enter Numbers of Days 1-60",
                    endingDate:"Ending Date",
                    projectDescription:"Project description",
                    projectDescriptionPlaceholder:"Write about your project",
                    projectDescriptionContent:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    riskChallenges:"Risk and Challenges",
                    riskChallengesContent:"Be honest about the potential risks and challenges of this project and how you plan to overcome them to complete it.",
                    riskChallengesContent2:"Risks you want to address includes budgeting, milestones etc",
                    yourTeam:"Your Team",
                    yourTeamContent:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    showPartOfTeam:"Show me as part of a team",
                    you:"You",
                    teamMemberAdd:"Add Team Member",
                    teamMemberEdit:"Edit Team Member",
                    full_name:"Full Name",
                    photo:"Photo",
                    yourCompany:"Your Company",
                    yourCompanyContent:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    optional:"Optional",
                    showLogoText:"Show your company logo as main on project page",
                    companyLogo:"Company Logo",
                    companyName:"Company Name",
                    additionalProjectManager:"Additional Project Manager",
                    additionalText:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",
                    projectManager:"Project Manager",
                    projectManagerText:"Add team members who will help you manage the project page. It is important to note that team members who do not have an account in Medicrowd will have to register in order to participate in project management.",
                    permissions:"Permissions",
                    edit_project:"Edit Project",
                    edit_project_description:"Make edits to your project's basic info, story",
                    manage_community:"Manage Community",
                    manage_community_description:"Draft and publish project updates, Post comments and mark comments as spam",
                    coordinate_fulfillment:"Coordinate Fulfillment",
                    coordinate_fulfillment_description:"View backing activity and access the backer report",
                    additionalManagerDelete:`אתה עומד למחוק הזמנה של מנהל פרויקטים.<br>שים לב שתוכל להזמין ולנהל את מנהלי הפרויקטים שלך במהלך הקמפיין דרך הדף "ניהול פרויקטים". האם תרצה להמשיך?`,
                    milestoneheader1:"There is a great deal of importance in explaining all milestones in a large project that relies on a lot of outside processes.",
                    milestoneheader2:"Every step of the project will be updated so your followers are informed of the progress. A minimum of three milestones must be added.",
                    addMilestone:"Add Milestone",
                    mileStone:"Milestone",
                    additionalInformation:"Additional information",
                    deleteMilestone:"Delete Milestone",
                    deleteMilestoneContent:"Are you sure you want to delete this milestone?",
                    fundingGoal:"Funding goal",
                    fundingGoalText:"Set a realistic goal that covers what you need to accomplish. By the end of the campaign, Medicrowd fees and processing fees will be charged to your funding goal. We strongly recommend that you factor those additional costs into your goal amount. To simulate the total amount of the goal, you can use our funding calculator",
                    goalAmount:"Goal Amount",
                    calculatorText:"Use our calculator to estimate total costs, including taxes and fees.",
                    fundingMilestone:"Funding Milestones",
                    fundingMilestoneText:"Show your followers and potential funders what the cost of every step of the project. If you choose milestones cost, it will appear in Project status tab on project page",
                    fundingCalculator:"Funding Calculator",
                    estimateBudget:"Estimated Budget",
                    enterAmount:"Enter the total amount you think you'll need to make this projects",
                    taxAdvice:"We can’t provide tax advice. See a professional adviser for additional guidance",
                    processingFeeText:`*Average processing fee—<span class="text-decoration-underline">this number varies</span> based on yourlocation and total number of Funders.`,
                    suggestedGoal:"Suggested goal",
                    suggestedGoalText:"We're providing this estimate to help you define your own funding goal. It's your responsibility to set the final amount.",
                    contactEmail:"Contact Email",
                    projectType:"Project type",
                    projectTypeText:"Select “Individual” if you’re raising and receiving funds for this project in your own name. Select “Business” if you’re raising and receiving funds for this project on behalf of an entity that you own or are an executive of, with authorization to represent.",
                    projectTypeText1:`By continuing, you agree to <a :class="{'text-grey' : isSubmittedforReview}" href="javascript:void(0);">X Connected Account Agreement</a> and consent to Stripe sharing your information with us. You won't be able to change this once you continue.`,
                    bankAccount:"Bank Account",
                    bankAccountText:"Add the checking account where you want to receive funds. This account must be located in Country the project located in, and able to receive direct deposits in the currency in which you raise funds. Projects raising funds in euros can use bank accounts in We don’t support wire transfers, savings accounts, or virtual bank accounts. You represent you're authorized to link this bank account to this project. If you’re raising funds as an individual, the account is registered in your name. If it’s on behalf of a business or nonprofit, the account is registered in the name of that entity.",
                    bankAccountText2:"The bank account receiving funds must be registered to the individual or business raising funds for this project.",
                    paymentSource:"Payment Source",
                    paymentSourceText:"Add a Visa, Mastercard, or American Express credit or debit card. Discover, JCB, Maestro, and Visa Electron cards are not accepted. This card must be registered to the individual or entity (or entity’s owner) raising funds for this project, and in their name. By adding this card, you agree Medicrowd may charge it for refunds, or in the event of lost chargeback disputes for your project.",
                    beneficiaryName:"Beneficiary Name",
                    credentialCorrectInfo:"Make sure all details are correct - you can’t change them after you submit your project for review. Medicrowd isn’t responsible for lost bank transfer as a result of incorrect or unsupported bank credentials or accounts",
                    bankCountry:"Bank Country",
                    bankCountryInfo:"This bank must be located in project country, and able to receive direct deposits in the currency in which you raise funds.",
                    cardDetailInfo:"By adding this card, you agree Medicrowd may charge it for refunds, or in the event of lost chargeback disputes for your project",
                    fraudText:"if a charge was fraudulent, made in error, or a store sends you a defective item, you can ask your car  issuer to have the payment reversed. Make sure all details are correct - you can’t change them after you submit your project for review.",
                    fraudText2:"if a charge was fraudulent, made in error, or a store sends you a defective item, you can ask your card issuer to have the payment reversed.",
                    projectURL:"Project URL",
                    projectURLInfo:"We'll use your current project title to generate a URL. Even if you change the title later, the URL will stay the same. Your project page won't go live until you launch.",
                    customReferralTags:"Custom referral tags",
                    customReferralTagsInfo:"Generate custom referral tags so you can create unique, trackable links for marketing initiatives outside of Medicrowd.",
                    googleAnalytics:"Google Analytics",
                    googleAnalyticsInfo:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                    generateTagText:'You may use this URL or you may edit the part of your URL that will work great for your future project promotion. Please pay attention to following:',
                    generateTagText1:'When composing your URL, use hyphens ("-") to separate words and \'/\' is not allowed.is more readable and search engine-friendly',
                    generateTagText2:"If possible, include your target keyword(s) in the URL. This can improve the page's relevance in search results.",
                    generateTagText3:'Stop words are common words like "and," "the," "of," etc., which add little value to a URL',
                    generateTagText4:"Please note, that after pressing confirm button, the edit option will be disabled.",
                    launchTheProject:"Launch the Project",
                    launchTheProjectInfo:"Great News! You are about to launch your project.",
                    launchTheProjectInfo1:"During the project running it will be impossible to change the following information: Funding category and country, funding duration, funding goal and payment information.",
                    launchTheProjectInfo2:"We recommend to run the additional check before starting the campaign",
                    launchTheProjectInfo3:"You will receive access to project management page right after the launch.",
                    launchTheProjectInfo4:"Are you sure you want to proceed?",
                    confirmAction:"Confirm Action",
                    completeMilestone:"Complete Milestone",
                    completeMilestoneInfo:"Congratulations! You are about to update your followers that the following milestone is completed. This information will be updated on your project page. Are you sure you want to proceed",
                    dailyVisitors:"Daily Visitors",
                    weeklyTotalVisits:"Weekly Total visits",
                    visitDuration:"Visit duration",
                    dailyFunders:"Daily Funders",
                    averageProjectViews:"Average Project views",
                    activity:"Activity",
                    totalViewers:"Total viewers",
                    fundersByCountry:"Funders by country",
                    usersReferralTags:"Users numbers per referral tags",
                    projectUpdate:"Project Update",
                    projectUpdateInfo:"Keep your funders informed about the project status, stay in touch, and share valuable information to convince more people of the project's importance. We recommend posting weekly updates",
                    updateList:"Updates List",
                    updateElementTitle:"Important updates for our Followers!",
                    deleteDraftContent:"Are you sure you want to proceed",
                    deleteUpdateHeader:"Delete Project Update",
                    deleteUpdateInfo:"You are about to delete an update to a project. Please keep in mind that I would be removed from project page as well.",
                    editUpdateInfo:"Edit Important updates for our Followers2",
                    deleteProjectManager:"Delete Project Manager",
                    deleteProjectManagerInfo:'You are about to delete a Project manager invitation.<br>Please note you will be able to invite and manager your project managers during\nthe campaign through “project management” page. Would you like to proceed?',
                    deleteTeamUser:"Delete Team User",
                    deleteTeamUserInfo:"You are about to delete a team member's information. <br>this means that potential funders won't see this information on the project page.<br> would you like to proceed?",
                    raised:"raised",
                    backers:"Backers",
                    daysToGo:"Days To Go",
                    projectCampaignInfo:"This Project campaign is over. But if you want to help this amazing project to succeed you still be able to fund it",
                    campaign:"Campaign",
                    teamUpdates:"Team Updates",
                    comments:"Comments",
                    risks:"Risks",
                    overview:"Overview",
                    overviewInfo:"There isn't much story on this page, let's fill it up",
                    overviewInfo2:"Be honest about the potential risks and challenges of this project <br /> and how you plan to overcome them to complete it.",
                    aboutInfo:"Tell about your team and about their professional experience",
                    mileStoneInfo:"Your project does not have any milestone information. Ensure potential funders are aware of the project process by updating it",
                    notifyEmail:"Notify User Via Email Regarding Request Secondary",
                    notifyWebsite:"Notify User Via Website Preview Massage",
                    chooseAmountFunding:"Choose the amount for your Funding",
                    totalAmount:"Total Amount",
                    addPaymentMethod:"Add New Payment Method",
                    saveCreditDetail:"Save the Credit Card Details",
                    pleaseNote:"Please Note",
                    backingInfo:"Backing means supporting a creative project, regardless of the outcome.",
                    agreeCheck:"I understand that rewards or reimbursements aren’t guaranteed by either Medicrowd or the creator",
                    leavingProject:"Are you leaving?",
                    leavingProjectInfo:" If you leave now, your progress will not be saved. You can either save your project as a draft and come back to finish it later, or leave without saving.",
                    warning:"Warning",
                    warningInfo:"You should first complete the basic step of project!",
                    submitReviewProject:"Submit Project to Review",
                    submitReviewProjectInfo:`<span>You are about to send this project for review. It may take our team several days to ensure your project follows our rules and guidelines, and that your bank account is linked. During the review you may add the following sections of the project: Basic, Story, Team,Milestones and promotion. </span><br><span> We recommend filling in the promotion information after receiving approval for the launch.</span><br><span>Please note that you can’t change Funding and Payment after submitting for review.Are you sure you want to proceed?</span>`,
                    meetOurTeam:"Meet Our Team",
                    ourCompany:"Our Company",
                    youDidIt:"You did it!",
                    youDidItInfo:"You're on air! Congratulations! That's awesome! We wish you the best of luck!By using Project Management Page, you can manage project information, update milestones, and communicate with your followers and potential funders. Alternatively, you can access it later by clicking on this project in your projects list.",
                    imageInfo:"Drop an image here, or select a file. It must be a JPG, PNG, GIF, or BMP, no larger than 10 MB.",
                    videoInfo:"Drop a video here, or select a file. It must be a MOV, MPEG, AVI, MP4, 3GP, WMV, or FLV, no larger than 20 MB.",
                    medicrowdFee:"Medicrowd fee",
                    processingFee:"Processing fee",
                    processingInfo:`*Average processing fee— <span class="text-decoration-underline">this number varies</span> based on your location and total number of Funders.`,
                    bankAccountMessage:"Complete the above steps to unlock this section",
                    bankName:"Bank Name",
                    expires:"Expires",
                    spamComment:"SPAM Comment",
                    description:"Description:",
                    projectUrlInfo:"You can’t generate your URL until you’ve submitted for review.",
                    insertLabel:"Insert Label",
                    trackingId:"Tracking ID",
                    reply:"Reply",
                    spam:"Spam",
                    peopleFunded:"People Funded",
                    target:"Target",
                    commentInfo:"Only backers can post comments.",
                    addCommentText:"Add a comment…",
                    of:"of",
                    projectCategory:"Project Category",
                    projectSubtitle:"Project subtitle - add short explanation regarding the issue for your research and why it is important",
                    by:"By"
                }
            }
        }
    }
}
