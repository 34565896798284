<template>
    <div>
<!--        <div class="overlay-box" id="overlay" v-if="getLoader">-->
<!--            <i class="fa fa-spin fa-spinner"></i>-->
<!--        </div>-->
        <Header />
        <div class="dashboard-pages my-0 my-md-5 pt-0 pt-md-5">
            <router-view />
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { mapGetters } from 'vuex';

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
    computed:{
        ...mapGetters(['getLoader'])
    }
};
</script>
